// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
const images = require.context('../img', true)

require("bootstrap")
import "../stylesheets/application";
import('stylesheets/application.scss');
import('stylesheets/scss/style.scss');
import('stylesheets/css/classy-nav.css');
import('stylesheets/css/animate.css');
import('stylesheets/css/magnific-popup.css');
import('stylesheets/css/font-awesome.min.css');
import('stylesheets/css/elegant-icon.css');

document.addEventListener("turbolinks:load", function() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
  })
})
